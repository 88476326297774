import Enumerable from "linq";

export const resolveAllergens = (item, components) => {
  const includedBase = Enumerable.from(item.allergens).distinct().toArray();

  // these are ids

  const includedComponents = Enumerable.from(components)
    .where((x) => x.absolute > 0 && x.levels.default > 0)
    .selectMany((x) => x.allergens)
    .distinct()
    .toArray();

  const added = Enumerable.from(components)
    .where((x) => x.absolute > 0 && x.levels.default == 0)
    .selectMany((x) => x.allergens)
    .distinct()
    .toArray();

  const removed = Enumerable.from(components)
    .where((x) => x.absolute == 0 && x.levels.default > 0)
    .selectMany((x) => x.allergens)
    .distinct()
    .toArray();

  const all = Enumerable.from(includedBase)
    .union(Enumerable.from(includedComponents))
    .union(Enumerable.from(added))
    .distinct()
    .toArray();

  const grayedOut = Enumerable.from(removed)
    .where((x) => !Enumerable.from(all).contains(x))
    .distinct()
    .toArray();

  return { all, grayedOut };
};
