<template>
  <div class="no-data" :class="{ full: isFullWidth }">{{ text }}</div>
</template>
<script>
  export default {
    props: {
      isFullWidth: {
        type: Boolean,
        default: true,
      },
      text: {
        type: String,
        default: "No Data",
      },
      minHeight: {
        type: String,
        default: "200px",
      },
      width: {
        type: String,
        default: "100%",
      },
    },
  };
</script>
<style lang="scss" scoped>
  .no-data {
    text-align: center;
    font-style: italic;
    color: $col_faded;
    width: v-bind("width");

    &.full {
      min-height: v-bind("minHeight");
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
</style>
