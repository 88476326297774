<template>
  <div class="service-menu__wrapper">
    <div v-if="loading" class="loading-spinner_container">
      <LoadingSpinner />
    </div>

    <div v-if="!loading" class="service-menu__content" ref="service-menu">
      <section class="service-header" ref="service-header">
        <div
          class="service-header_img-container"
          v-if="service?.vendor.thumbnails?.length > 0">
          <ImageCarousel
            :assets="service.vendor.thumbnails"
            :alt="service?.vendor?.name" />
        </div>
        <div class="service-header_info">
          <h2>{{ service?.vendor?.name }}</h2>
          <TrucatableDescription
            v-if="service?.vendor.description"
            :description="service?.vendor.description" />

          <div class="row align-center g-1">
            <router-link
              :to="{ path: '/venue/' + service?.service?.venue?.code }">
              <p class="location row align-center">
                <Icon :path="mdiMapMarker" :size="16" />
                {{
                  replace(
                    service?.service?.pitch?.site?.name,
                    service?.service?.venue?.name
                  )
                }},
                {{
                  formatAddress(
                    service?.service?.pitch?.site?.address ??
                      service?.service?.address
                  )
                }}
              </p>
            </router-link>
          </div>

          <div class="row g-1">
            <p class="date row align-center">
              <Icon :path="mdiCalendar" :size="16" />
              {{ formatDate(service?.service?.startDate) }}
            </p>
            <p
              class="more-detail row align-center"
              @click="this.showModal = 'vendorAllergensModal'">
              <Icon :path="mdiInformation" :size="16" />
              Allergens
            </p>
            <p
              class="more-detail row align-center"
              @click="this.showModal = 'vendorContactModal'">
              <Icon :path="mdiPhone" :size="16" />
              Contact
            </p>
          </div>

          <div class="row g-1" style="margin-top: 5px">
            <!-- <a
            v-if="showCallToOrder()"
            class="button"
            :href="'tel:' + getCallToOrderNumber()"
            ><Icon :path="mdiPhone" :size="16" /> Order by Phone</a
          > -->
            <p v-if="service?.vendor?.website">
              <a target="website" :href="`https://${service?.vendor?.website}`">
                <Icon :path="mdiWeb" :size="24" />
              </a>
            </p>
            <p v-if="service?.vendor?.socials?.facebook">
              <a
                target="facebook"
                :href="`https://facebook.com/${service?.vendor?.socials?.facebook}`">
                <Icon :path="mdiFacebook" :size="24" />
              </a>
            </p>
            <p v-if="service?.vendor?.socials?.instagram">
              <a
                target="instagram"
                :href="`https://instagram.com/${service?.vendor?.socials?.instagram}`">
                <Icon :path="mdiInstagram" :size="24" />
              </a>
            </p>
            <p v-if="service?.vendor?.socials?.tikTok">
              <a
                target="tikTok"
                :href="`https://tiktok.com/@${service?.vendor?.socials?.tikTok}`">
                <Icon
                  path="M18.7,6.3c-0.1-0.1-0.3-0.1-0.4-0.2c-0.4-0.2-0.7-0.5-1.1-0.9c-0.8-0.9-1.1-1.7-1.2-2.4h0C16,2.3,16,2,16,2h-3.6v13.3
	c0,0.2,0,0.4,0,0.5c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,1-0.6,1.8-1.5,2.3c-0.4,0.2-1,0.4-1.5,0.4c-1.7,0-3-1.3-3-2.9
	s1.3-2.9,3-2.9c0.3,0,0.6,0,0.9,0.1l0-3.5c-1.8-0.2-3.7,0.3-5.1,1.4c-0.6,0.5-1.1,1.1-1.5,1.8C3.6,12.9,3,13.9,3,15.6
	c0,0.9,0.2,1.9,0.4,2.3v0c0.1,0.2,0.4,1,1,1.7c0.4,0.5,1,1,1.5,1.4v0l0,0C7.6,22.1,9.5,22,9.5,22c0.3,0,1.4,0,2.7-0.6
	c1.4-0.6,2.2-1.6,2.2-1.6c0.5-0.6,0.9-1.2,1.2-1.9c0.3-0.8,0.4-1.8,0.4-2.2v-7c0,0,0.6,0.4,0.6,0.4s0.8,0.5,2.1,0.8
	c0.9,0.2,2.2,0.3,2.2,0.3V6.8C20.5,6.9,19.6,6.7,18.7,6.3z"
                  :size="24" />
              </a>
            </p>
            <p v-if="service?.vendor?.socials?.twitter">
              <a
                target="twitter"
                :href="`https://twitter.com/${service?.vendor?.socials?.twitter}`">
                <Icon :path="mdiTwitter" :size="24" />
              </a>
            </p>
            <p v-if="service?.vendor?.socials?.youTube">
              <a
                target="youtube"
                :href="`https://youtube.com/${service?.vendor?.socials?.youTube}`">
                <Icon :path="mdiYoutube" :size="24" />
              </a>
            </p>
          </div>
        </div>

        <div class="row map" v-if="service?.coordinate && 1 == 2">
          <MapBox :origin="service?.coordinate" />
        </div>
      </section>

      <div class="section-shortcuts" ref="shortcut">
        <p class="section-shortcuts_date" v-show="showDateOnShortcut">
          <Icon :path="mdiCalendar" :size="16" />
          {{ formatDate(service?.service?.startDate, "dd/MM") }}
        </p>
        <div class="section-shortcuts_list_outer shortcut-nav">
          <div
            class="section-shortcuts_list_inner"
            v-if="availableSections?.length > 0">
            <a
              v-for="section in availableSections"
              :key="section.id"
              class="block-select"
              :class="{ active: Number(activeSectionId) === section.id }"
              @click="scrollTo(section.id)">
              {{ section.name }}
            </a>
          </div>
        </div>
      </div>

      <ul
        class="menu-sections"
        v-if="availableSections?.length > 0"
        ref="menu-sections">
        <li
          v-for="section in availableSections"
          :key="section.id"
          :id="section.id">
          <div :id="`section-${section.id}`" class="section-anchor"></div>
          <h3>{{ section.name }}</h3>

          <ul class="menu-items">
            <li
              v-for="item in section.itemData"
              :key="item.id"
              class="menu-item"
              @click="selectItem(item, section)"
              :class="[
                { outOfStock: item.outOfStock || item.isServiceBlocked },
                { overCapacity: overCapacity(item) },
              ]">
              <div
                v-if="item.thumbnails.length"
                class="thumb"
                :style="getCssThumbnail(item.thumbnails[0])"></div>

              <div class="detail">
                <h4>
                  <Icon
                    :path="mdiStarShooting"
                    v-if="item.topSeller"
                    class="topSeller" />
                  {{ item.name }}
                  <small
                    v-if="getRestrictions(item)"
                    style="font-size: 0.8em; margin-left: 10px">
                    (🕛11:30 - 2:30)
                  </small>
                </h4>
                <h5
                  v-if="
                    !item.outOfStock &&
                    !item.isServiceBlocked &&
                    !overCapacity(item) &&
                    item.price > 0 // Hiding all free items for now, coveres licensing issues on beer
                  "
                  class="price">
                  {{ this.formatPrice(item.price) }}
                  <span v-if="item.spiceLevel > 0" class="spice">
                    <Icon
                      :path="this.getSpiceIcon(item.spiceLevel)"
                      :title="this.getSpiceTitle(item.spiceLevel)" />
                  </span>
                </h5>
                <p
                  v-if="item.outOfStock || item.isServiceBlocked"
                  class="outOfStock">
                  Out of stock
                </p>
                <p v-if="overCapacity(item)" class="outOfStock">
                  Item limit reached
                </p>
                <p
                  class="description"
                  v-if="
                    item.description != null &&
                    !item.outOfStock &&
                    !item.isServiceBlocked &&
                    !overCapacity(item)
                  ">
                  {{ item.description }}
                </p>
                <p class="description" v-else></p>
                <div
                  class="dietaries row"
                  v-if="
                    !item.outOfStock &&
                    !item.isServiceBlocked &&
                    item.dietaries &&
                    item.dietaries.length &&
                    !overCapacity(item)
                  ">
                  <p class="dietary row">
                    <Icon :path="mdiCheckCircle" :size="16" />{{
                      getDietaries(item)
                    }}
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </li>
      </ul>
      <NoDataText v-else text="No Menu Items" />
    </div>

    <teleport to="body">
      <transition name="fade">
        <ModalDialog
          :tabletLayout="tabletLayout"
          :mobileLayout="mobileLayout"
          :serviceName="outlet.nameDisplay"
          :serviceDate="service.service.startDate"
          :serviceId="serviceId"
          :selectedItemProp="selectedItem"
          :selectedSection="selectedSection"
          :allDietaries="allDietaries"
          :disabled="!onlineOrderingAvailable"
          :orderingAvailable="service.event.isOrderingAvailable"
          v-if="showModal === 'addItemModal'"
          @close="showModal = false"
          @refreshMenu="refreshMenu"
          class="modal customise-item" />
      </transition>
    </teleport>
    <teleport to="body">
      <transition name="fade">
        <div class="modal-mask" v-if="showModal === 'vendorContactModal'">
          <div class="modal-wrapper">
            <div class="modal-container flex-center column">
              <div class="close-modal" @click.stop="showModal = false">
                <Icon :path="mdiClose" :size="24" />
              </div>
              <div class="column vendor-detail">
                <div
                  class="detail row g-small"
                  v-if="outlet?.mainContact?.emailAddress">
                  <p class="title">Email</p>
                  <p class="value">
                    {{ outlet.mainContact.emailAddress }}
                  </p>
                </div>
                <div
                  class="detail row g-small"
                  v-if="outlet?.mainContact?.phoneOffice">
                  <p class="title">Phone</p>
                  <p class="value">
                    {{ outlet.mainContact.phoneOffice }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </teleport>
    <teleport to="body">
      <transition name="fade">
        <div class="modal-mask" v-if="showModal === 'vendorAllergensModal'">
          <div class="modal-wrapper">
            <div class="modal-container flex-center column">
              <div class="close-modal" @click.stop="showModal = false">
                <Icon :path="mdiClose" :size="24" />
              </div>
              <div class="column vendor-detail">
                <div
                  class="detail row g-small"
                  v-if="service.vendor.mainContact.emailAddress">
                  <p>
                    We strive to accurately label allergens and dietary
                    requirements for our dishes but cannot guarantee they will
                    be completely free from trace allergens.
                  </p>
                  <p>
                    If you have any severe allergies or dietary requirements,
                    please contact the vendor before placing your order to
                    ensure they can accommodate your needs.
                  </p>
                  <p>
                    It is your responsibility to inform the vendor of any
                    critical requirements.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </teleport>
  </div>
</template>

<script>
  import Enumerable from "linq";
  import ModalDialog from "@/components/ModalDialog.vue";
  import NoDataText from "@/components/NoDataText";
  import MapBox from "@/components/maps/MapBox.vue";
  import store from "@/store";
  import { resolveDietary } from "@/helpers/resolveDietary";
  import { replace } from "@tucktrucks/core";
  import { formatAddress } from "@tucktrucks/platform-base-public";
  import { mapGetters } from "vuex";
  import {
    mdiMapMarker,
    mdiCalendar,
    mdiInformation,
    mdiPhone,
    mdiCheckCircle,
    mdiClose,
    mdiWeb,
    mdiFacebook,
    mdiInstagram,
    mdiTwitter,
    mdiYoutube,
    mdiChiliMild,
    mdiChiliMedium,
    mdiChiliHot,
    mdiChiliAlert,
    mdiStarShooting,
  } from "@mdi/js";
  import ImageCarousel from "@/components/Images/ImageCarousel.vue";
  import { humanize, transform, toTitleCase } from "@alduino/humanizer/string";
  import TrucatableDescription from "@/components/Vendor/TrucatableDescription.vue";
  import {
    calculateAdjustedBlockPositions,
    initObserver,
  } from "@/helpers/scrollTracking";
  import { getThumbnailUrl, getCssThumbnail } from "@/helpers/images";

  export default {
    props: {
      mobileLayout: {
        type: Boolean,
      },
      tabletLayout: {
        type: Boolean,
      },
    },

    components: {
      ModalDialog,
      NoDataText,
      MapBox,
      ImageCarousel,
      TrucatableDescription,
    },

    data() {
      return {
        loading: false,
        showModal: false,
        serviceId: parseInt(this.$route.params.serviceId),
        selectedItem: null,
        service: null,
        outlet: null,
        availableSections: null,
        allDietaries: [],
        resolveDietary,
        showDateOnShortcut: false,
        activeSectionId: null,
        sectionEls: [],
        sectionBreakPoints: [],

        formatAddress,
        replace,

        mdiMapMarker,
        mdiCalendar,
        mdiInformation,
        mdiPhone,
        mdiCheckCircle,
        mdiClose,
        mdiWeb,
        mdiFacebook,
        mdiInstagram,
        mdiTwitter,
        mdiYoutube,
        mdiChiliMild,
        mdiChiliMedium,
        mdiChiliHot,
        mdiChiliAlert,
        mdiStarShooting,
      };
    },

    computed: {
      ...mapGetters({
        getCartSummary: "cart/getSummary",
        getVendorById: "repoVendors/getById",
      }),

      onlineOrderingAvailable() {
        if (!this.service) return false;

        const channelNames = this.service.service.channels.map((x) => x.name);

        return (
          channelNames.includes("ClickCollect") ||
          channelNames.includes("TableService") ||
          channelNames.includes("Delivery")
        );
      },

      orderingAvailable() {
        return this.service.event.isOrderingAvailable;
      },
    },

    emits: ["refreshMenu", "cartVisible"],

    methods: {
      initObserver,
      getThumbnailUrl,
      getCssThumbnail,

      // should be using resolveDietaries.js
      getDietaries(item) {
        let array = item?.dietaries ?? [];

        if (array.includes("Vegan")) {
          array = array.filter((x) => x != "Vegetarian");
          array = array.filter((x) => x != "Pescatarian");
        }

        if (array.includes("Vegetarian")) {
          array = array.filter((x) => x != "Pescatarian");
        }

        let dairyFree = array.indexOf("DairyFree");
        if (dairyFree > -1) {
          array[dairyFree] = "Dairy Free";
        }

        let glutenFree = array.indexOf("GlutenFree");
        if (glutenFree > -1) {
          array[glutenFree] = "Gluten Free";
        }

        return (
          array.map((x) => transform(humanize(x), toTitleCase)).join(", ") ?? ""
        );
      },

      formatPrice(price) {
        if (price <= 0) {
          return "Free";
        }

        return this.formatMoney(price);
      },

      getVendorThumbnail(vendor) {
        return this.getVendorById(vendor.id).then((vendor) => {
          let thumbnailSrc = [];

          for (let thumbnail of vendor.thumbnails) {
            thumbnailSrc.push(getThumbnailUrl(thumbnail));
          }

          return thumbnailSrc;
        });
      },

      getSpiceIcon(level) {
        switch (level) {
          case 1:
            return mdiChiliMild;
          case 2:
            return mdiChiliMedium;
          case 3:
            return mdiChiliHot;
          case 4:
            return mdiChiliAlert;
        }
      },
      getSpiceTitle(level) {
        switch (level) {
          case 1:
            return "Mild";
          case 2:
            return "Medium";
          case 3:
            return "Hot";
          case 4:
            return "Extreme";
        }
      },

      showCallToOrder() {
        if (this.service == null) {
          return false;
        }

        try {
          return this.service.service.channels
            .map((x) => x.name)
            .includes("Phone");
        } catch {
          return false;
        }
      },

      getCallToOrderNumber() {
        return this.outlet.mainContact.phoneOffice;
      },

      refreshMenu() {
        this.$emit("refreshMenu");
      },

      getOrderedDietaryTypeNames(identifiers) {
        return Enumerable.from(this.allDietaries)
          .where((x) => identifiers.includes(x.identifier))
          .toArray();
      },

      scrollTo(id) {
        this.activeSectionId = id;
        const element = document.getElementById(`section-${id}`);

        const headerHeight =
          this.$refs["service-header"].getBoundingClientRect().height;

        const shortcutHeight =
          this.$refs["shortcut"].getBoundingClientRect().height;
        const top = element.offsetTop + 56 + headerHeight + shortcutHeight; // 56: the nav height

        this.$refs["service-menu"].scrollTo({
          top,
          left: 0,
          behavior: "smooth",
        });
      },

      selectItem(item, section) {
        if (item.outOfStock || item.isServiceBlocked) {
          return;
        }

        this.selectedItem = item;
        this.selectedSection = section;
        this.showModal = "addItemModal";
      },

      getSections() {
        if (this.service?.menu?.sections == null) {
          return [];
        }

        return Enumerable.from(this.service.menu.sections)
          .where((x) => x.availablePublic)
          .toArray();
      },

      async updateMenu() {
        this.loading = true;
        this.selectedItem = null;

        // get service
        this.service = await store.getters["repoServices/getByIdWithQuery"](
          this.serviceId,
          {
            includeMenu: true,
            includeItems: true,
            includeComponents: true,
            includeServiceComponents: true,
            includeServiceItems: true,
            includeEvent: true,
            includeVendor: true,
            methods: 3,
          }
        );

        this.outlet = await store.getters["repoOutlets/getById"](
          this.service.service.outlet.id
        );

        const { items, components, vendor, serviceItems, serviceComponents } =
          this.service;

        // create document title
        document.title = vendor.name + ": " + vendor.description;

        this.service.vendor.thumbnails = await this.getVendorThumbnail(vendor);

        if (serviceItems?.length > 0) {
          // upsert serviceItems into repo
          for (const serviceItem of serviceItems) {
            await store.dispatch("repoServiceItems/upsert", serviceItem);
          }
        }

        if (items?.length > 0) {
          // Upsert items into repo
          for (const item of items) {
            await store.dispatch("repoItems/upsert", item);
          }
        }

        if (serviceComponents?.length > 0) {
          // upsert serviceComponents into repo
          for (const serviceComponent of serviceComponents) {
            await store.dispatch(
              "repoServiceComponents/upsert",
              serviceComponent
            );
          }
        }

        if (components?.length > 0) {
          // Upsert Components into repo
          for (const component of components) {
            await store.dispatch("repoComponents/upsert", component);
          }
        }

        // get available menu sections
        const availableSections = this.getSections();

        this.availableSections = availableSections;

        // grab the item data now, then we have it
        if (availableSections != null) {
          for (const section of availableSections) {
            if (section?.items != null) {
              const menuSectionItems = [];

              for (const itemId of section.items) {
                // get item from items
                const item = items.find(({ id }) => id === itemId);

                // check if item is service blocked
                const serviceBlockedItemIds = serviceItems
                  .filter(({ isServiceBlocked }) => isServiceBlocked)
                  .map(({ itemId }) => itemId);

                item.isServiceBlocked = serviceBlockedItemIds?.includes(
                  item.id
                );

                // getting all service blocked component Ids to check if component is service blocked
                const serviceBlockedComponentIds = serviceComponents
                  .filter(({ isServiceBlocked }) => isServiceBlocked)
                  .map(({ componentId }) => componentId);

                for (const [index, itemComponent] of Object.entries(
                  item.components
                )) {
                  const componentBasicInfo = components.find(
                    ({ id }) => id === itemComponent.componentId
                  );

                  const isComponentServiceBlocked =
                    serviceBlockedComponentIds?.includes(
                      itemComponent.componentId
                    );

                  const isComponentOutOfStock = componentBasicInfo.outOfStock;

                  const absolute =
                    isComponentServiceBlocked || isComponentOutOfStock
                      ? 0
                      : itemComponent.levels.default;

                  const componentWithFullData = {
                    ...itemComponent,
                    ...componentBasicInfo,
                    absolute,
                    delta: 0,
                    isServiceBlocked: isComponentServiceBlocked,
                  };

                  // check if any required components are service blocked or out of stock
                  if (
                    itemComponent.levels.default > 0 &&
                    (isComponentServiceBlocked || isComponentOutOfStock)
                  ) {
                    item.outOfStock = true;
                  }

                  item.components[index] = componentWithFullData;
                }
                item.dietaries = resolveDietary(item, item.components);

                menuSectionItems.push(item);
                section.itemData = menuSectionItems;
              }
            }
          }
        }

        if (!this.onlineOrderingAvailable && this.orderingAvailable === false)
          this.$emit("cartHidden");
        else this.$emit("cartVisible");

        this.loading = false;
      },

      getRestrictions(item) {
        return item.restrictTimeLunch;
      },

      isVegan(item) {
        return item.dietaryType.includes("Vegan");
      },

      isVegetarian(item) {
        return !this.isVegan(item) && item.dietaryType.includes("Vegetarian");
      },

      setCartVisability() {
        if (!this.onlineOrderingAvailable) this.$emit("cartHidden");
      },

      overCapacity(item) {
        return (
          this.getCartSummary.complexity + item.complexity > 24 &&
          item.complexity > 0
        );
      },

      scrollDetect() {
        const ifShortcutFixed =
          this.$refs["shortcut"]?.getBoundingClientRect().top === 56;

        this.showDateOnShortcut = ifShortcutFixed;
      },
    },

    async mounted() {
      this.$store
        .dispatch("repoServices/loadService", this.serviceId)
        .then(async () => await store.dispatch("cart/validateCart"));

      await this.updateMenu();
      if (!this.onlineOrderingAvailable) this.$emit("cartHidden");

      try {
        store.state.apiPublic.client.endpoints.enumsfe.then((response) => {
          this.allDietaries = response.data.data;
        });
      } catch (error) {
        window.log.log("getDietaryTypeFlags", { error });
      }

      this.$refs["service-menu"]?.addEventListener("scroll", this.scrollDetect);

      const blockIds = this.availableSections.map(({ id }) => id);

      const { blockEls, blockBreakpoints } =
        calculateAdjustedBlockPositions(blockIds);

      this.sectionEls = blockEls;
      this.sectionBreakPoints = blockBreakpoints;

      this.initObserver(
        this.sectionEls,
        (id) => (this.activeSectionId = Number(id))
      );
    },

    beforeUnmount() {
      this.$refs["service-menu"]?.removeEventListener(
        "scroll",
        this.scrollDetect
      );
    },
  };
</script>

<style lang="scss">
  .service-menu {
    padding-top: $topbar_height;
    &__content {
      height: calc(100vh - $topbar_height);
      overflow: auto;
      position: relative;
    }
  }

  .cart-view {
    background: $col_light_grey_green;
  }

  .button {
    padding: 0.2em;
    font-size: 0.8em;
    text-decoration: none;

    .icon-wrapper {
      margin-right: 0.5em;
    }
  }

  .menu-sections {
    background: $col_offwhite;
    padding: 1rem 1rem 0;
    position: relative;

    h3 {
      margin-top: 1.5rem;
      margin-bottom: 1rem;
      padding-left: 0.25rem;
      font-weight: 500;
      @media screen and (max-width: 480px) {
        margin-bottom: 0.5rem;
      }
    }

    li:first-child {
      h3 {
        margin-top: 0;
      }
    }

    .mobileLayout & {
      padding: 2rem;
      @media screen and (max-width: 480px) {
        padding: 1rem;
      }
    }
  }

  .section-anchor {
    font-size: 0;
    height: 0;
    position: relative;
    top: -8rem;
  }

  .menu-items {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    gap: 1rem;

    .mobileLayout & {
      flex-direction: column;
    }
  }

  .menu-item {
    border-radius: $card_radius;
    background: #fff;
    color: #666;
    transition: 0.2s;
    overflow: hidden;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    height: 120px;
    width: calc(50% - 1rem);
    min-width: 400px;

    @media screen and (max-width: $tablet_max_width) {
      width: 100%;
      min-width: unset;
    }

    .thumb {
      display: block;
      width: 100px;
      height: 100px;
      background-size: cover;
      background-position: center;
      flex-shrink: 0;
      border-radius: $card_radius;
      margin: 10px 0 10px 10px;
    }

    .detail {
      padding: 0.75rem 1rem;
      width: 100%;

      h4 {
        padding: 0;
        margin: 0;
      }

      p.description {
        flex-grow: 1;
        flex-shrink: 1;
        font-size: 0.85rem;
        line-height: 1.2em;
        margin-top: 0.25rem;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }

    h4 {
      color: #000;
      line-height: 1.2em;
      font-size: 1.2rem;

      .icon-wrapper.topSeller {
        color: #e8b923;
        float: right;

        position: relative;
        top: -5px;
        left: 1px;
      }
    }

    h5 {
      &.price {
        font-size: 1rem;
        display: flex;
        gap: 0.5em;
        color: $col_black;
      }

      .spice {
        color: #e32227;
      }
    }

    p {
      line-height: 1.5em;
    }

    &:hover {
      box-shadow: $card_shadow;
    }

    .outOfStock {
      color: #e32227;
      font-size: 0.8rem;
    }

    &.outOfStock {
      cursor: initial;
      &:hover {
        box-shadow: none;
      }
      h4 {
        color: $col_gray;
      }
    }

    &.overCapacity {
      pointer-events: none;
      cursor: initial;
      &:hover {
        box-shadow: none;
      }
      h4 {
        color: $col_gray;
      }
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.35s ease;
  }
  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }

  .section-shortcuts {
    @include flex($dir: row, $ai: center, $g: 0);
    width: 100% !important;
    background: $col_white;
    backdrop-filter: blur(10px);
    padding: 0.85rem 0 0 !important;
    margin: 0 !important;
    box-shadow: $card_shadow;

    overflow: hidden;
    position: sticky;
    top: 0;
    z-index: 2;

    &_list_outer {
      overflow-x: auto;
      width: 100% !important;
      padding: 0 0.5rem 0.85rem;
    }

    &_date {
      @include flex($dir: row, $ai: center, $g: 0.25rem);
      margin: 0 0 0.85rem 1rem;
      padding-right: 0.5rem;
      border-right: 1px solid $col_gray;
      font-size: 0.875rem;
      font-weight: 600;
    }

    .mobileLayout & {
      width: 100%;
      margin: -10px 0 0 -15px;
      padding: 0;
    }

    .cart-view & {
      width: 60%;
      margin: -10px 0 0 -25px;
    }

    .mobileLayout .cart-view & {
      width: 100%;
      margin: 0;
    }

    a {
      display: inline-block;
      background-color: $col_white;
      border: 1px solid $col_beta;
      border-radius: $button_radius;
      margin: 0 0.5rem 0 0;
      cursor: pointer;
      font-size: 0.9rem;
      text-decoration: none;
      color: $col_beta_darker;
      transition: background-color 0.15s;
      padding: 0.35rem 1rem;
    }
  }

  .section-shortcuts_list_inner {
    white-space: nowrap;
    position: relative;

    a.active {
      background-color: $col_beta-darker;
      color: $col_white;
    }
  }
  .customise-item {
    .modal-container {
      max-width: 1000px;
      padding: 0;
    }
  }
  .menu-sections {
    .allergen-dietary-section {
      padding: 0 !important;
      background: none !important;
      gap: 0;
    }
    p.allergen {
      font-size: 0.75rem !important;
      color: #999;
      margin-bottom: 0 !important;
    }
  }
  .ingredients {
    font-size: 0.85rem !important;
    margin-bottom: 0.15rem !important;
    line-height: 1.2em !important;
  }
  .components {
    margin-bottom: 0.25rem;
  }
  div.dietaries {
    flex-wrap: wrap;
    align-items: center;
    gap: 0.15rem;

    p.dietary {
      font-size: 0.75rem;
      align-items: flex-start;
      color: $col_alpha;
      margin-bottom: 0;
      gap: 0.15rem;
    }
  }
  .service-header {
    @include flex($dir: row, $ai: stretch);
    padding: 0 !important;
    margin: 0 !important;
    width: 100%;
    background: #fff;
    border-bottom: 1px solid #ddd;

    .mobileLayout &,
    .tabletLayout & {
      padding: 0 !important;
    }

    .map {
      width: 300px;
      height: 164px;

      @media screen and (max-width: 990px) {
        width: 200px;
      }

      @media screen and (max-width: 480px) {
        display: none;
      }
    }

    @media screen and (max-width: 480px) {
      flex-direction: column;
      gap: 0.5rem;
      border-bottom: none;
    }

    &_img-container {
      width: 33%;
      position: relative;
      display: flex;
      align-items: center;
      overflow: hidden;
      justify-content: center;

      @include outlet-list-header-image;

      .carousel__viewport {
        border-radius: 5px;
      }

      @media screen and (max-width: $mobile_max_width) {
        width: 100%;
        height: 100%;
        margin-left: 0;
        padding-top: 0;
        margin: 0;
      }
    }

    &_info {
      h2 {
        @include view-main-title;
      }
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
      padding: 1rem;
      width: 67%;

      p {
        margin-top: 0 !important;
        font-size: 0.8rem;
        gap: 0.25rem;
        align-items: center;
        .material-icons-round.small {
          font-size: 0.875rem;
        }
      }

      .description {
        overflow: hidden;
        padding-right: 2rem;
        line-height: 1.5em;
        padding-left: 0.15rem;

        p {
          height: initial;
          overflow: visible;
          transition: 0.2s height;
          line-height: 1.5em;
          padding-bottom: 0.5rem;
        }
        .more {
          text-decoration: underline;
          cursor: pointer;
          font-size: 0.8rem;
          line-height: 1.1em;
          color: $col_faded;
        }

        &.trucated {
          p {
            height: 37px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
        }

        &.long {
          p {
            padding-bottom: 0;
          }
        }
      }
      .more-detail {
        cursor: pointer;
      }

      @media screen and (max-width: 480px) {
        width: 100%;
        padding: 0 1rem;
        h2 {
          font-size: 1.2rem;
          line-height: 1.1em;
          padding-top: 0.5rem;
        }
        p,
        .material-icons-round.small {
          font-size: 0.8rem !important;
          line-height: 1.5em;
        }
        .more {
          font-size: 0.75rem;
          line-height: 1.1em;
        }
        .description {
          padding-right: 0;
        }
      }
    }
  }
  .vendor-detail {
    justify-content: flex-start;
    width: 100%;
    p {
      margin: 0;
    }
    h3 {
      margin-bottom: 0.5rem;
    }
    @media screen and (max-width: 480px) {
      gap: 0.5rem;
      .detail {
        flex-direction: column;
        gap: 0 !important;
      }
    }
    .title {
      color: $col_faded-darker;
      width: 80px;
    }
  }
</style>
