let blockBreakpoints;
let blockEls;

export const calculateAdjustedBlockPositions = (idBlocks) => {
  blockEls = idBlocks.map((el) => document.getElementById(el));

  const viewportHeight = window.innerHeight;
  const docHeight =
    document.querySelector(".section-shortcuts").offsetHeight +
    document.querySelector(".menu-sections").offsetHeight +
    document.querySelector(".service-header").offsetHeight;

  const scrollTop = document
    .querySelector(".service-header")
    .getBoundingClientRect().top;

  blockBreakpoints = blockEls.map((el) => {
    const top = el.getBoundingClientRect().top;
    const oldOffset = top + scrollTop;
    return oldOffset - oldOffset * (viewportHeight / docHeight);
  });

  return { blockEls, blockBreakpoints };
};

export const initObserver = (sectionEls, callback) => {
  const options = {
    root: null,
    threshold: [...Array(10)].map((el, index) => 0.1 * index),
  };

  const handleIntersectionEvent = () => {
    if (!document.querySelector(".service-header")) return;

    const scrollTop = document
      .querySelector(".service-header")
      .getBoundingClientRect().top;

    blockBreakpoints.find((bp, index) => {
      const offsetTop =
        document.querySelector(".section-shortcuts").offsetHeight;

      if (Math.abs(scrollTop + bp) < offsetTop + 50) {
        const activeNav = document.querySelectorAll(".block-select")[index];
        const activeNavLeft = activeNav.getBoundingClientRect().x;

        document.querySelector(".shortcut-nav").scrollTo({
          left: index !== 0 ? activeNavLeft : null,
          behavior: "smooth",
        });
        return callback(Number(sectionEls[index].id));
      }
    });
  };

  const observer = new IntersectionObserver(handleIntersectionEvent, options);

  return sectionEls.forEach((el) => observer.observe(el));
};
