import Enumerable from "linq";
import { humanize, transform, toTitleCase } from "@alduino/humanizer/string";

export const resolveDietary = (item, components) => {
  if (components.length === 0) return item.dietaryType;
  const includedBase = Enumerable.from(item.dietaryType).distinct().toArray();

  const includedComponentAllDietary = Enumerable.from(components)
    .where((x) => x.absolute > 0 && x.levels.default > 0)
    .select((x) => x.dietaryType)
    .toArray();

  let includedComponents =
    includedComponentAllDietary.length > 0
      ? includedComponentAllDietary.reduce((a, b) =>
          a.filter((c) => b.includes(c))
        )
      : [];

  const addedArrays = Enumerable.from(components)
    .where((x) => x.absolute > 0 && x.levels.default == 0)
    .select((x) => x.dietaryType)
    .toArray();

  let added =
    addedArrays.length > 0
      ? addedArrays.reduce((a, b) => a.filter((c) => b.includes(c)))
      : [];

  let all = Enumerable.from(includedBase).intersect(includedComponents);

  if (added.length > 0) {
    all = all.intersect(added);
  }

  all = all
    .distinct()
    .select((x) => transform(humanize(x), toTitleCase))
    .toArray();

  if (all.includes("Vegan")) {
    all = all.filter((x) => x != "Vegetarian");
    all = all.filter((x) => x != "Pescatarian");
  } else if (all.includes("Vegetarian")) {
    all = all.filter((x) => x != "Pescatarian");
  }

  return all;
};
