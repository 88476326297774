<template>
  <div
    @click="
      amount === selected
        ? this.$emit('handleUnselectAmount')
        : this.$emit('handleSelectAmount', amount)
    "
    class="component-amounts_option"
    :class="{
      default: amount === levels.default,
      selected: amount === selected,
      hide,
    }">
    <template v-if="!hide">
      <span class="amount">{{ getAmount }}</span>
      <span class="priceToAdd" v-if="amount !== levels.default && amount !== 0">
        {{ getSign(amount, levels, priceToAdd)
        }}{{ formatMoney(getLevelAmount(amount, levels, priceToAdd)) }}
      </span>
    </template>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        selected: this.levels.default,
      };
    },
    props: {
      selectedAmount: {
        type: Number,
      },
      amount: {
        type: Number,
      },
      levels: {
        type: Object,
      },
      priceToAdd: {
        type: Number,
      },
      extras: {
        type: Boolean,
      },
      hide: { type: Boolean },
    },
    watch: {
      selectedAmount: function () {
        this.selected = this.selectedAmount;
      },
    },
    emits: ["handleSelectAmount", "handleUnselectAmount"],
    methods: {
      getLevelAmount(amount, levels, priceToAdd) {
        return (amount - levels.default) * priceToAdd;
      },
      getSign(amount, levels, priceToAdd) {
        return this.getLevelAmount(amount, levels, priceToAdd) >= 0 ? "+" : "";
      },
    },
    computed: {
      getAmount() {
        const defaultValue = this.levels.default;
        const amount = this.amount;

        if (amount === 0) {
          return "None";
        }

        if (amount === defaultValue) {
          return "Regular";
        }

        if (amount !== defaultValue) {
          const diff = amount - defaultValue;
          return diff >= 0 ? "+" + diff : diff;
        }
        return "+" + amount;
      },
    },
  };
</script>

<style lang="scss">
  .component-amounts {
    &_option {
      width: 80px;
      @include outlined-button($p: 2px 0, $col: #999, $h: 33px);
      @media screen and (max-width: 768px) {
        display: inline-flex;
      }

      &.hide {
        border: none !important;
      }
      .amount {
        padding: 0 5px;
        font-weight: 600;
        font-size: 0.7rem;
        color: black;
      }
      .priceToAdd {
        font-size: 0.7rem;
        color: #999;
      }
      &.default {
        @include outlined-button($p: 2px 0, $col: $col_alpha, $h: 33px);
        .amount {
          font-weight: 500;
          padding: 0;
          color: $col_alpha;
        }
      }
      &.selected,
      &.selected:hover {
        @include contained-button($p: 2px 0, $h: 33px);
        border-width: 1.5px !important;
        span {
          font-weight: bold !important;
          color: white !important;
        }
      }

      &.hide {
        border: none;
        padding: 0;
        pointer-events: none;
        @media screen and (max-width: 768px) {
          display: none;
        }
        span,
        select {
          display: none;
        }
      }
    }
  }
</style>
