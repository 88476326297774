<template>
  <div
    class="description"
    :class="[
      { initial: !ifReady },
      {
        long: ifVendorDescriptionTooLong,
      },
      { trucated: ifVendorDescriptionTrucated },
    ]">
    <p ref="description">
      {{ description }}
    </p>
    <span
      class="more"
      @click="this.toggleVendorDescription"
      v-if="ifVendorDescriptionTooLong">
      {{ ifVendorDescriptionTrucated ? "more" : "Show less" }}</span
    >
  </div>
</template>

<script>
  export default {
    data() {
      return {
        ifReady: false,
        ifVendorDescriptionTooLong: false,
        ifVendorDescriptionTrucated: false,
      };
    },
    methods: {
      toggleVendorDescription() {
        this.ifVendorDescriptionTrucated = !this.ifVendorDescriptionTrucated;
      },
    },
    props: {
      description: {
        type: String,
      },
    },
    async mounted() {
      // check if description is too long
      setTimeout(() => {
        if (
          this.$refs["description"] &&
          this.$refs["description"].scrollHeight > 45
        ) {
          this.ifVendorDescriptionTrucated = true;
          this.ifVendorDescriptionTooLong = true;
        }
        this.ifReady = true;
      }, 300);
    },
  };
</script>

<style lang="scss" scoped>
  .description {
    p {
      margin-top: 0;
    }
    &.initial {
      max-height: 67px;
      p {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
    &.trucated {
      p {
        max-height: 45px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }

    &.long {
      p {
        padding-bottom: 0;
      }
    }

    .more {
      text-decoration: underline;
    }
  }
</style>
