<template>
  <div class="modal-mask" @click.prevent="$emit('close')">
    <div
      class="modal-wrapper add-item-modal-wrapper"
      :class="{ tense: ifModalTense, mobileLayout, tabletLayout }"
      :style="{ height: windowHeight }">
      <div
        class="modal-container"
        @click.stop=""
        ref="modal-container"
        :class="{ tense: ifModalTense }">
        <div class="close-modal" @click.stop="$emit('close')">
          <Icon :path="mdiClose" :size="24" />
        </div>

        <div v-if="loading" class="loading-spinner_container">
          <LoadingSpinner />
        </div>
        <div
          v-else
          class="content-wrapper"
          ref="modal-content"
          :style="{ maxHeight: modalBodyMaxHeight }">
          <section class="header" ref="modal-header">
            <div
              v-if="selectedItem.thumbnails.length"
              class="thumb"
              :style="getCssThumbnail(selectedItem.thumbnails[0])"></div>
            <div class="summary" v-if="!mobileLayout && !tabletLayout">
              <h4 class="vendor">
                {{ serviceName }}
                <span class="dot"> </span>
                {{ formatDate(serviceDate) }}
              </h4>
              <h2 class="item-name">{{ selectedItem.name }}</h2>
              <p class="note mb-1">
                {{ selectedItem.description }}
              </p>
              <AllergensDisplay
                :allergensProp="this.allAllergens"
                :grayedOutAllergensProp="this.grayedOutAllergens" />

              <DietaryDisplay
                :dietariesProp="this.dietaries"
                v-if="dietaries.length > 0" />
            </div>
          </section>

          <section class="customise">
            <template v-if="mobileLayout || tabletLayout">
              <div class="item-info" ref="mobile-item-info">
                <h4 class="vendor">
                  {{ serviceName }}
                  <span class="dot"> </span>
                  {{ formatDate(serviceDate) }}
                </h4>
                <h2 class="item-name">{{ selectedItem.name }}</h2>
              </div>

              <p class="note mb-1">
                {{ selectedItem.description }}
              </p>
            </template>
            <div class="customise-content-wrapper">
              <div v-if="comesWith?.length" class="mb-1 customise-content base">
                <h3>Customise your item</h3>
                <ItemComponentsList
                  :componentList="comesWith"
                  @modifyComponentAmount="modifyComponentAmount" />
              </div>

              <div v-if="extras?.length" class="mb-1 customise-content">
                <h3>Want some extra love?</h3>
                <ItemComponentsList
                  :componentList="extras"
                  :extras="true"
                  @modifyComponentAmount="modifyComponentAmount" />
              </div>
            </div>
            <div v-if="!disabled" class="add-items-wrapper" ref="modal-footer">
              <div
                class="column g-small pinned-details"
                v-if="mobileLayout || tabletLayout">
                <AllergensDisplay
                  :allergensProp="this.allAllergens"
                  :grayedOutAllergensProp="this.grayedOutAllergens" />

                <DietaryDisplay
                  :dietariesProp="this.dietaries"
                  v-if="dietaries.length > 0" />
              </div>
              <div class="modal-actions" v-if="orderingAvailable">
                <div class="addItemsCounter flex-center">
                  <button
                    class="round"
                    @click="decreaseItemCount"
                    :disabled="addItemCount === 1"
                    :class="{ limit: lowerLimitReached }">
                    <Icon :path="mdiMinus" title="Remove" />
                  </button>
                  <p class="count">{{ addItemCount }}</p>
                  <button
                    v-if="this.checkComplexity(selectedItem, addItemCount)"
                    class="round"
                    @click="increaseItemCount"
                    :class="{ limit: upperLimitReached }">
                    <Icon :path="mdiPlus" title="Add" />
                  </button>
                </div>

                <button
                  @click="
                    addItem(
                      selectedItem,
                      selectedSection,
                      addItemCount,
                      localComponentsState
                    )
                  "
                  class="add"
                  :disabled="loading || !getCart.cartKey">
                  <LoadingSpinner v-if="loading || !getCart.cartKey" />
                  <span v-else>
                    Add for
                    <span class="bold">{{ formatMoney(getPrice) }}</span>
                  </span>
                </button>
              </div>
              <div v-else>
                <h3
                  style="
                    text-align: center;
                    padding: 1rem 0 0 0;
                    color: #ff7575;
                  ">
                  Same day online ordering only!
                </h3>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import store from "@/store";
  import { mapGetters } from "vuex";
  import { itemHash } from "@tucktrucks/platform-base-public";
  import { resolveAllergens } from "@/helpers/resolveAllergens";
  import { resolveDietary } from "@/helpers/resolveDietary";
  import AllergensDisplay from "@/components/AllergensDisplay.vue";
  import DietaryDisplay from "@/components/DietaryDisplay.vue";
  import ItemComponentsList from "@/components/ItemComponentsList";
  import { mdiPlus, mdiMinus, mdiClose } from "@mdi/js";
  import { getThumbnailUrl, getCssThumbnail } from "@/helpers/images";

  export default {
    components: {
      AllergensDisplay,
      DietaryDisplay,
      ItemComponentsList,
    },

    data() {
      return {
        loading: false,
        lowerLimit: 1,
        upperLimit: 99,
        lowerLimitReached: false,
        upperLimitReached: false,
        addItemCount: 1,
        alterableComponents: null,
        selectedItem: this.selectedItemProp,
        selectedItemComponents: [],
        localComponentsState: [],
        allAllergens: [],
        grayedOutAllergens: [],
        dietaries: [],
        allergens: [],
        modalBodyMaxHeight: null,
        customiseBodyMaxHeight: null,
        windowHeight: null,
        ifModalTense: false,
        mdiPlus,
        mdiMinus,
        mdiClose,
      };
    },

    props: {
      mobileLayout: { type: Boolean },
      tabletLayout: { type: Boolean },
      serviceName: { type: String },
      serviceDate: { type: String },
      serviceId: {
        type: Number,
      },

      selectedItemProp: {
        type: Object,
      },

      selectedSection: {
        type: Object,
      },

      allDietaries: {
        type: Array,
      },

      disabled: {
        type: Boolean,
        default: false,
      },

      orderingAvailable: {
        type: Boolean,
        default: false,
      },
    },

    emits: ["refreshMenu", "close"],

    methods: {
      getThumbnailUrl,
      getCssThumbnail,

      modifyComponentAmount({ id, delta, absolute }) {
        // check absolute isn't less than 0

        const a = absolute < 0 ? 0 : absolute;

        // update local component state array
        this.localComponentsState.forEach((comp) => {
          if (comp.id === id) {
            comp.delta = delta;
            comp.absolute = a;
          }
        });

        this.updateAllergens();
        this.updateDietary();
      },

      updateAllergens() {
        this.allergens = resolveAllergens(
          this.selectedItem,
          this.localComponentsState
        );

        this.allAllergens = this.allergens.all;
        this.grayedOutAllergens = this.allergens.grayedOut;
      },

      updateDietary() {
        this.dietaries = resolveDietary(
          this.selectedItem,
          this.localComponentsState
        );
      },

      async addItem(item, section, amount, componentAlterations) {
        this.loading = true;

        await store.dispatch(
          "cart/upsertItem",
          itemHash(item, section, amount, componentAlterations)
        );

        const cartValid = await store.dispatch("cart/validateCart");

        if (!cartValid) {
          this.$emit("refreshMenu");
        }

        this.$emit("close");
      },

      decreaseItemCount() {
        this.addItemCount--;
        this.lowerLimitReached = false;
        if (this.addItemCount === 0) {
          this.addItemCount = this.lowerLimit;
          this.lowerLimitReached = true;
        }
      },
      increaseItemCount() {
        this.addItemCount++;
        this.upperLimitReached = false;
        if (this.addItemCount === this.upperLimit + 1) {
          this.addItemCount = this.upperLimit;
          this.upperLimitReached = true;
        }
      },
      getModalMaxBodyHeight() {
        // release the previous height limitation
        this.modalBodyMaxHeight = null;
        this.ifModalTense = false;

        // get the new max height for the new window size
        setTimeout(() => {
          const modalTopAndBottomPadding = this.mobileLayout ? 80 : 100;
          const windowHeight = window.innerHeight;
          const modalFooterHeight = this.$refs["modal-footer"]?.clientHeight;
          const modalNaturalHeight =
            this.$refs["modal-content"]?.offsetHeight +
            modalTopAndBottomPadding;

          this.windowHeight = windowHeight + "px";

          if (modalNaturalHeight > windowHeight) {
            this.modalBodyMaxHeight = windowHeight - modalFooterHeight + "px";
            this.ifModalTense = true;
          }
        }, 1);
      },

      checkComplexity(selectedItem, addItemCount) {
        const complexity = this.getCartSummary?.complexity ?? 0;

        if (
          complexity + selectedItem.complexity * addItemCount + 1 <= 24 ||
          selectedItem.complexity === 0
        ) {
          return true;
        }
        return false;
      },
    },

    computed: {
      ...mapGetters({
        getCart: "cart/get",
        getCartSummary: "cart/getSummary",
      }),

      // Locally defined sub-groups (of localComponentsState): comesWith and extras.
      // This distinction only exists for front end display purposes.
      // localComponentsState is the source of local component state.
      comesWith() {
        return this.localComponentsState.filter((x) => {
          // Included by default, AND is either removable or able to add more
          return x.levels.default > 0 && x.levels.minimum != x.levels.maximum;
        });
      },

      extras() {
        return this.localComponentsState.filter((x) => {
          // zero by default, min at least 1
          return x.levels.default === 0 && x.levels.maximum > 0;
        });
      },

      getFixed() {
        return this.localComponentsState.filter((x) => {
          return (
            x.levels.default === x.levels.minimum &&
            x.levels.maximum === x.levels.minimum &&
            x.levels.default === x.levels.maximum
          );
        });
      },

      getPrice() {
        var price = this.selectedItem?.price ?? 0;

        // This is only additive for now, as we don't yet support discounts for removing components.
        for (const component of this.localComponentsState) {
          if (component.absolute > component.levels.default) {
            price +=
              (component.absolute - component.levels.default) *
              component.priceToAdd; //component.level
          }
        }

        price *= this.addItemCount;

        return price;
      },
    },
    async created() {
      this.loading = true;

      try {
        const itemComponents = [];

        if (this.selectedItem.components.length > 0) {
          this.selectedItem.components.forEach((itemComponent) => {
            itemComponents.push(itemComponent);
          });
        }

        for (const itemComponent of itemComponents) {
          const { outOfStock, isServiceBlocked, levels } = itemComponent;

          itemComponent.delta = 0;
          itemComponent.absolute =
            outOfStock || isServiceBlocked ? 0 : levels.default;

          this.localComponentsState.push(itemComponent);
        }
      } catch (error) {
        window.log.error(error);
      } finally {
        this.updateAllergens();
        this.updateDietary();
        this.loading = false;
      }
    },
    mounted() {
      this.getModalMaxBodyHeight();
      window.addEventListener("resize", this.getModalMaxBodyHeight);
    },
    unmounted() {
      window.removeEventListener("resize", this.getModalMaxBodyHeight);
    },
  };
</script>

<style lang="scss">
  h2.item-name {
    margin-right: 30px;
    margin-top: 0;
    line-height: 1em;
    @media screen and (max-width: $mobile_max_width) {
      font-size: 1.2rem;
    }
  }

  .customise-content-wrapper {
    width: 100%;
  }

  .component-alterations-scroll {
    height: auto;
  }

  .addItemsCounter {
    display: flex;
    justify-content: space-between;
    font-size: 1.5rem;
    margin: 1rem 0;
    @media screen and (max-width: $mobile_max_width) {
      margin: 0.5rem 0;
    }
    @media (orientation: landscape) {
      margin: 0.5rem 0;
    }
    p.count {
      width: 50px;
      text-align: center;
    }
  }

  .component-alterations-list {
    margin-top: 0 !important;
  }

  .component-alterations-list_item {
    display: flex;
    justify-content: space-between;
    padding: 0.35rem 1.5rem 0.35rem 0;
    align-items: center;

    &:not(:last-child) {
      border-bottom: 1px dotted #ddd;
    }

    input {
      margin-right: 0.5rem;
      cursor: pointer;
    }

    label {
      cursor: pointer;
    }

    .outOfStock {
      cursor: default;
      opacity: 0.33;

      * {
        cursor: default;
      }
    }
  }

  .component-alterations-list_price {
    color: $col_faded;
    margin-top: 0;
  }

  .limit {
    color: $col_faded;
  }

  .allergens-list {
    padding: 0;
  }

  .content-wrapper {
    @include flex($jc: center, $dir: row, $ai: stretch, $g: 0);
    height: 100%;
    width: 100%;

    > section {
      padding: 0;
      margin: 0;
    }

    .header {
      flex: none;
      max-width: 380px;
      width: 30%;
      padding: 0;

      .thumb {
        display: block;
        width: 100%;
        padding-top: 100%;
        height: 0;
        background-size: cover;
        background-position: center;
        flex-shrink: 0;
      }

      .summary {
        padding: 1rem;

        .note {
          font-size: 0.875rem;
          margin-bottom: 0;
        }

        .allergen-dietary-section {
          margin-top: 1rem;
        }
      }
    }

    .vendor {
      color: $col_alpha-darker;
      font-size: 0.785rem;
      margin-bottom: 0.5rem;

      .dot {
        background: lightgray;
        width: 2px;
        height: 2px;
        border-radius: 50%;
        margin: 0.5rem;
      }
    }

    // Mobile / Pad Potrait mode
    @media screen and (max-width: $mobileLayout_breakpoint_width) and (orientation: portrait) {
      height: auto;
      display: block;
      overflow: auto;

      .header {
        width: 100%;
        max-width: unset;
        padding: 0;

        .thumb {
          width: 100%;
          padding-top: 56.25%;
          height: 0;
        }

        .summary {
          padding-bottom: 0;
        }

        &__vendor {
          position: absolute;
          top: 1rem;
          background: white;
          border-radius: 0.25rem;
          padding: 0.25rem 0.5rem;
          z-index: 5;
        }
      }

      .customise-content-wrapper {
        max-height: unset;
      }
    }

    .tense & {
      justify-content: space-between;
    }

    .customise {
      padding: 1rem;
      border-left: 1px solid lightgray;

      .tabletLayout &,
      .mobileLayout & {
        .item-info {
          position: sticky;
          background: white;
          z-index: 9;
          top: 0;
          right: 0;
          padding: 1rem 0;
        }

        .note {
          margin-bottom: 1rem;
        }
      }
    }
  }

  .add-item-modal-wrapper {
    overflow: auto;
    display: flex !important;
    margin: auto;
    align-items: center;

    .close-modal {
      z-index: 10;
      border-radius: 50%;
      background: white;
      padding: 0.5rem;
    }

    @media screen and (max-width: $mobile_max_width) {
      padding: 0;
      .close-modal {
        top: 1rem;
        right: 1rem;
      }

      .note {
        word-wrap: break-word;
      }

      .tense {
        .note {
          margin-bottom: 0.25rem;
        }
      }
    }

    @media (orientation: landscape) {
      p.note {
        margin-bottom: 0.25rem;
      }
    }

    .modal-container {
      width: 100%;
      display: flex;
      flex-direction: row;

      // Mobile / Pad Potrait mode
      @media screen and (max-width: $mobileLayout_breakpoint_width) and (orientation: portrait) {
        width: 100% !important;
        height: auto;
        border-radius: 0;

        &.tense {
          height: 100%;
        }
      }

      h4 {
        margin-right: 0;
        line-height: 1.3em;
        display: flex;
        align-items: center;
        flex-direction: row;
      }
      @media screen and (max-width: $mobile_max_width) {
        h4 {
          align-items: flex-start;
        }
      }
    }

    .customise-content-wrapper {
      overflow: auto;
      max-height: 50vh;

      // Mobile / Pad Potrait mode
      @media screen and (max-width: $mobileLayout_breakpoint_width) and (orientation: portrait) {
        max-height: unset;
      }
    }

    .customise-content {
      h3 {
        font-size: 1.1rem;
        background: #f4f4f4;
        font-weight: 900;
        padding: 0.25rem 0.5rem;
        @media screen and (max-width: $mobile_max_width) {
          font-size: 0.875rem;
          font-weight: 600;
          padding: 0.3rem 0.5rem 0.2rem;
        }
      }
      ul.component-alterations-list {
        margin-top: -0.1rem;
      }
    }

    .info-button {
      top: 0;
      .material-design-icon {
        margin-left: 5px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .fixed-ingredients {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    font-size: 0.8rem;
    @media screen and (max-width: $mobile_max_width) {
      flex-direction: column;
      .tense & {
        gap: 0;
        margin-bottom: 0.5rem;
      }
    }
    &_title {
      font-weight: bold;
      margin-top: 0;
    }
    &_list {
      margin-top: 0;
    }
    li {
      font-size: 0.8rem;
      display: inline;
      line-height: 1.1em;
    }
    li:not(:last-child)::after {
      content: ", ";
      display: inline-block;
      padding-right: 0.25rem;
    }
  }

  .pinned-details {
    margin: 0 1.5rem;
    @media screen and (max-width: $mobile_max_width) {
      margin: 0 1rem;
    }
  }
  .tense {
    .allergen-dietary-section {
      max-height: 50px;
      overflow-y: auto;

      @media (orientation: landscape) {
        max-height: 30px;
      }
    }
  }
  .modal-actions {
    button.add {
      @include contained-button;
      min-width: 100px;
    }

    button.round {
      @include outlined-button(
        $p: 2px 0,
        $col: $col_beta-darker,
        $h: 40px,
        $radius: 50%
      );
    }

    @include flex($ai: center, $jc: center, $g: 1rem, $dir: row);
    max-width: 360px;
    width: 90%;
    margin: 0.5rem auto 0;
    .addItemsCounter {
      margin: 0;
    }
  }

  .add-items-wrapper {
    width: 100%;
    background: white;
    padding-top: 1rem;
    padding-bottom: 1rem;

    // Mobile / Pad Potrait mode
    .tense & {
      @media screen and (max-width: $mobileLayout_breakpoint_width) and (orientation: portrait) {
        position: absolute;
        z-index: 9;
        bottom: 0;
        left: 0;
      }
    }
  }
</style>
