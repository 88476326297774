<template>
  <li class="component-alterations-list_item">
    <div
      class="label-container column"
      :class="{
        outOfStock: component.outOfStock || component.isServiceBlocked,
      }">
      <label :for="component.id">{{ component.name }}</label>
      <ExpandableAllergensDisplay
        :allergensProp="allergens"
        v-if="!component.outOfStock && !component.isServiceBlocked" />
      <p
        v-if="component.outOfStock || component.isServiceBlocked"
        class="outOfStock">
        Out of stock
      </p>
    </div>
    <div class="component-options-container">
      <!-- for None option -->
      <ItemComponentAmountOption
        :hide="!this.extras && ifHideNoneOption"
        :key="0 + component.id"
        @handleSelectAmount="handleSelectAmount(0)"
        @handleUnselectAmount="handleUnselectAmount"
        :selectedAmount="selectedAmount"
        :levels="component.levels"
        :amount="0" />
      <ul
        class="component-amounts flex-wrap"
        :class="{ extras: this.extras }"
        v-if="!component.outOfStock && !component.isServiceBlocked">
        <!-- the first 5 options -->
        <li v-for="amount in amountOptions" :key="amount + component.id">
          <ItemComponentAmountOption
            @handleSelectAmount="handleSelectAmount(amount)"
            @handleUnselectAmount="handleUnselectAmount"
            :extras="this.extras"
            :selectedAmount="selectedAmount"
            :levels="component.levels"
            :amount="amount"
            :priceToAdd="component.priceToAdd" />
        </li>

        <!-- more options if provided  -->
        <li
          class="component-amounts_more-options"
          v-if="restOptionsForSelect.length > 0">
          <select
            :class="{ active: restOptionsForSelect.includes(selectedAmount) }"
            @change="(e) => handleSelectAmount(Number(e.target.value))"
            :value="selectedAmount">
            <option value="">...</option>
            <option
              :value="amount"
              v-for="amount of restOptionsForSelect"
              v-bind:key="`level` + amount">
              +{{ amount - component.levels.default }} (+{{
                formatMoney(
                  (amount - component.levels.default) * component.priceToAdd
                )
              }})
            </option>
          </select>
        </li>
      </ul>
    </div>
  </li>
</template>

<script>
  import ItemComponentAmountOption from "@/components/ItemComponentAmountOption.vue";
  import ExpandableAllergensDisplay from "@/components/ExpandableAllergensDisplay.vue";
  import store from "@/store";

  export default {
    components: { ItemComponentAmountOption, ExpandableAllergensDisplay },
    data() {
      let totalOptions = [];
      const initial = !this.extras
        ? this.component.levels.default
        : this.component.levels.minimum || 1;

      for (let i = initial; i <= this.component.levels.maximum; i++) {
        totalOptions.push(i);
      }

      const amountOptions = totalOptions.slice(0, 5);
      const restOptionsForSelect = totalOptions.slice(5);

      return {
        amountOptions,
        restOptionsForSelect,
        selectedAmount: null,
        allergens: [],
      };
    },
    props: {
      component: {
        type: Object,
      },
      extras: {
        type: Boolean,
      },
    },
    computed: {
      ifHideNoneOption() {
        return this.component.levels.minimum !== 0;
      },
    },
    emits: ["modifyComponentAmount"],
    methods: {
      handleSelectAmount(amount) {
        this.selectedAmount = amount;
        this.$emit("modifyComponentAmount", {
          id: this.component.id,
          absolute: this.selectedAmount,
          delta: this.selectedAmount - this.component.levels.default,
        });
      },
      handleUnselectAmount() {
        if (this.extras) {
          this.selectedAmount = 0;
        } else {
          this.selectedAmount = this.component.levels.default;
        }
        this.$emit("modifyComponentAmount", {
          id: this.component.id,
          absolute: this.selectedAmount,
          delta: this.selectedAmount - this.component.levels.default,
        });
      },
    },
    async created() {
      if (this.component?.allergens?.length > 0) {
        Promise.all(
          this.component.allergens.map(async (id) => {
            const allergens = await store.getters["repoAllergens/getById"](id);
            return allergens;
          })
        ).then((data) => {
          this.allergens = data;
        });
      }
    },
  };
</script>

<style lang="scss">
  .component-amounts {
    display: flex;
    flex-direction: row;
    margin-top: 0;
    margin-bottom: 0rem;
    gap: 0.5rem;
    justify-content: flex-start;
    width: 256px;

    @media screen and (max-width: 768px) {
      flex-wrap: nowrap;
      display: inline-flex;
      width: 0;
    }

    &_more-options {
      width: 80px;
      display: inline-flex;
      select {
        width: 100%;
        padding: 4px 8px;
        border-radius: 0.25rem;
        border: 1px solid #ddd;
        font-size: 0.8rem;
        @media screen and (max-width: 768px) {
          width: initial;
        }
        &.active {
          color: #fff;
          background: $col_beta-darker;
          border-color: $col_beta-darker;
        }
        option {
          color: rgb(0, 0, 0);
          background: #fff;
        }
      }
    }

    &_more-options {
      &.hide {
        border: none;
        padding: 0;
        span,
        select {
          display: none;
        }
      }
    }
  }

  .component-alterations-list {
    &_item {
      padding: 0.35rem 0;
      gap: 0.5rem;
      align-items: center;

      .label-container {
        justify-content: space-between;
        label {
          font-size: 1rem;
          font-weight: 600;
          width: 160px;
          @media screen and (max-width: 768px) {
            width: 130px;
          }
        }
      }

      @media screen and (max-width: 600px) {
        flex-direction: row;
        align-items: baseline;

        .base & {
          flex-direction: column;
          .label-container {
            flex-direction: row;
            width: 100%;
            align-items: flex-start;
            & > * {
              width: 50%;
              flex-shrink: 0;
            }
            label {
              line-height: 1.3em;
            }
          }
        }
      }
    }
  }

  .label-container.outOfStock {
    cursor: initial;
    opacity: 1;

    color: $col_gray;

    .outOfStock {
      color: #d80707;
      font-size: 0.8rem;
      margin: 0;
    }
  }

  .component-options-container {
    width: 350px;
    @include flex($dir: row, $jc: flex-start, $ai: flex-start);

    @media screen and (max-width: 768px) {
      width: 100%;
      overflow-y: auto;
      justify-content: initial;
      padding-bottom: 10px;
    }
  }
</style>
