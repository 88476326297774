<template>
  <div
    class="map"
    ref="map"
    :style="{ height: mapSize.height, width: mapSize.width }">
    <div class="map-holder">
      <div id="map"></div>
      <!-- <div id="instructions"></div> -->
    </div>
  </div>
</template>

<script>
  const mapboxgl = window.mapboxgl;
  import { mapGetters } from "vuex";
  import { parseLatLng } from "@tucktrucks/core";

  export default {
    data() {
      return {
        loading: false,
        location: "",
        access_token: process.env.VUE_APP_MAP_ACCESS_TOKEN,
        map: null,
        profile: "cycling",
        start: this.origin,
        steps: true,
        venueMarker: null,
        destinationMarkers: {},
        mapSize: { height: null, width: null },
      };
    },

    props: {
      fullMode: {
        type: Boolean,
      },
      origin: {
        type: String,
        default: "-1.30792 51.58269",
      },
    },

    computed: {
      ...mapGetters({}),
    },

    watch: {},

    methods: {
      createMap() {
        try {
          mapboxgl.accessToken = this.access_token;

          let start = parseLatLng(this.start);
          let map = new mapboxgl.Map({
            container: "map",
            center: start,
            style: "mapbox://styles/chris-tt/cl1dyz5ft005a14r0y122wvof",
            zoom: 14,
            attributionControl: false,
            interactive: false,
          });

          //map.addControl(new mapboxgl.FullscreenControl());

          this.map = map;
        } catch (err) {
          window.log.error(err);
        }

        let point = parseLatLng(this.origin);
        const marker = new mapboxgl.Marker({
          color: "#faa9a9",
        });
        marker.setLngLat(point);
        marker.addTo(this.map);
        this.map.flyTo({ center: point, zoom: 15 });
      },
    },

    mounted() {
      this.createMap();
    },
  };
</script>

<style lang="scss" scoped>
  @import "https://cdnjs.cloudflare.com/ajax/libs/mapbox-gl/2.10.0/mapbox-gl.min.css";

  .map {
    .map-holder {
      width: 100%;
      height: 100%;

      #map {
        width: 100%;
        height: 100%;
      }
    }
  }

  #instructions {
    position: absolute;
    margin: 20px;
    width: 25%;
    top: 0;
    bottom: 20%;
    padding: 20px;
    background-color: #fff;
    overflow-y: scroll;
  }
</style>
